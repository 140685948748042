import { reactive, ssrRef } from '@nuxtjs/composition-api';

import { BlockColorContent } from '@/graphql/generated';
import { usePageContent } from '@/modules/cms';

const pageBgColorRef = ssrRef(null, 'pageBgColor');
const pageTextColorRef = ssrRef(null, 'pageTextColor');

export const state = reactive({
  pageBgColor: pageBgColorRef,
  pageTextColor: pageTextColorRef,
});

export const mutations = {
  setColors (blockColorAttributes: BlockColorContent | null) {
    let timeout: any;
    const callback = () => {
      pageBgColorRef.value = blockColorAttributes?.bgColor;
      pageTextColorRef.value = blockColorAttributes?.textColor;

      clearTimeout(timeout);
    };

    // eslint-disable-next-line dot-notation
    if (process.client && window['requestIdleCallback']) {
      // @ts-ignore
      window.requestIdleCallback(callback);
      timeout = setTimeout(callback, 1000);
    } else {
      callback();
    }
  },
};

/**
 * Set page color
 */
export const usePageColor = () => {
  const { pageContent } = usePageContent();
  mutations.setColors(pageContent.value.blockColorAttribute);

  return {};
};
