
import { Component, Vue, Watch } from 'nuxt-property-decorator';

import { state } from './BodyBackgroundState';
import { getContrastTextColor } from './contrast-utils';

@Component
export default class BodyBackground extends Vue {
  head () {
    return {
      meta: [{ hid: 'theme-color', name: 'theme-color', content: this.backgroundColor }],
    };
  }

  render () {
    return '';
  }

  get backgroundColor () {
    return state.pageBgColor;
  }

  mounted () {
    this.updatePageColor();
  }

  @Watch('backgroundColor')
  changeBackgroundColor () {
    this.updatePageColor();
  }

  updatePageColor () {
    document.body.style.background = state.pageBgColor || null;
    document.body.style.color = state.pageTextColor || null;
    document.body.style.setProperty('--current-color', state.pageTextColor || null);
    document.body.style.setProperty('--current-color-inverted', getContrastTextColor(state.pageTextColor));
  }
}
